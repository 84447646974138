<template>
  <channel-selector
    :class="{ inactive: !isActive }"
    :title="channel.name"
    :src="getChannelThumbnail()"
    @click="onItemClick"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import ChannelSelector from '../ChannelSelector';
export default {
  components: { ChannelSelector },
  props: {
    channel: {
      type: Object,
      required: true
    },
    enabledFeatures: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isFeatureEnabledonPlan: 'accounts/isFeatureEnabledonPlan'
    }),
    hasFbConfigured() {
      return window.chatwootConfig?.fbAppId;
    },
    isUsable() {
      const { key } = this.channel;
      if (key === 'facebook') {
        return this.hasFbConfigured;
      }
      return true;
    },
    isFeatureOnAccount() {
      const { key } = this.channel;
      if (Object.keys(this.enabledFeatures).length === 0) {
        return false;
      }
      if (key === 'website') {
        return this.enabledFeatures.channel_website;
      }
      if (key === 'facebook') {
        return this.enabledFeatures.channel_facebook;
      }
      if (key === 'email') {
        return this.enabledFeatures.channel_email;
      }

      return [
        'twilio',
        'api',
        'whatsapp',
        'sms',
        'telegram',
        'telegram_mt',
        'line'
      ].includes(key);
    },
    isFeatureOnPlan() {
      const { key } = this.channel;
      const feature_name = `channel_${key}`;
      return this.isFeatureEnabledonPlan(feature_name);
    },
    isActive() {
      return this.isUsable && this.isFeatureOnAccount && this.isFeatureOnPlan;
    }
  },
  methods: {
    getChannelThumbnail() {
      if (this.channel.key === 'api' && this.channel.thumbnail) {
        return this.channel.thumbnail;
      }
      return `/assets/images/dashboard/channels/${this.channel.key}.png`;
    },
    onItemClick() {
      if (this.isActive) {
        this.$emit('channel-item-click', this.channel.key);
      }
    }
  }
};
</script>
