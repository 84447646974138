<template>
  <div class="flex-1 overflow-auto p-4">
    <div class="flex flex-col">
      <woot-loading-state
        v-if="uiflags.isLoadingUsdtAddress"
        :message="$t('PAYMENT_MGMT.LOADING')"
      />
      <div v-else>
        <div>
          <h3 class="text-base font-semibold leading-7 text-black-900">{{ $t('PAYMENT_MGMT.PAYMENT_USDT.TITLE') }}</h3>
          <p class="mt-1 max-w-2xl text-sm leading-6 text-black-600">{{ description }}</p>
        </div>
        <div class="my-2 px-3 py-2 max-w-2xl border bg-white border-black-300 relative">
          <!-- <div class="flex justify-center items-center h-screen"> -->
          <div class="relative inline-flex my-2">
            <div class="w-6 h-6 bg-woot-500 rounded-full" />
            <div class="w-6 h-6 bg-woot-500 rounded-full absolute top-0 left-0 animate-ping" />
            <div class="w-6 h-6 bg-woot-500 rounded-full absolute top-0 left-0 animate-pulse" />
            <h3 class="text-base font-bold ml-2">{{ $t('PAYMENT_MGMT.PAYMENT_USDT.AWAIT_PAY') }}</h3>
          </div>
          <p>USDT {{ $t('PAYMENT_MGMT.PAYMENT_USDT.WALLET_ADDRESS') }}（TRC20）</p>
          <div class="flex">
            <input :value="address" type="text" size="34" class="usdt--input" readonly />
            <woot-button class="ml-2" variant="link" size="small" @click="copyToClipboard(address)">
              {{ $t('PAYMENT_MGMT.PAYMENT_USDT.COPY_ADDRESS') }}
            </woot-button>
            <woot-button class="ml-2" variant="link" size="small" @click="showQrcode">
              {{ $t('PAYMENT_MGMT.PAYMENT_USDT.SHOW_QRCODE') }}
            </woot-button>
          </div>
          <p>{{ $t('PAYMENT_MGMT.PAYMENT_USDT.AMOUNT') }}</p>
          <input :value="amount" type="text" class="usdt--input" size="34" readonly />
          <p v-dompurify-html="notice" />
          <p class="mt-2 text-sm font-bold align-middle text-black-600">{{ $t('PAYMENT_MGMT.PAYMENT_USDT.COMMENT') }}</p>
          <img src="https://payid19.com/img/TRC20.svg" class="absolute bottom-2 right-5 w-40 h-40" />
        </div>
        <div v-if="isUpgrade" class="my-2 px-3 py-2 max-w-2xl border bg-white border-black-300">
          <h3 class="text-base font-bold">{{ $t('PAYMENT_MGMT.PAYMENT_USDT.UPGRADE_INFO') }}</h3>
          <div class="pb-2">
            {{ $t('PAYMENT_MGMT.PAYMENT_USDT.PLAN') }}{{ currentPlanName }} > {{ plan_name }}<br />
            {{ $t('PAYMENT_MGMT.PAYMENT_USDT.USER_COUNT') }}{{ currentUserCount }} > {{ user_count }}<br />
            {{ $t('PAYMENT_MGMT.PAYMENT_USDT.EXPIRE') }}{{ $t('PAYMENT_MGMT.PAYMENT_USDT.UNCHANGED') }}<br />
            {{ $t('PAYMENT_MGMT.PAYMENT_USDT.PRICE') }}{{ amount }} U<br />
          </div>
          <span class="text-base">{{ $t('PAYMENT_MGMT.PAYMENT_USDT.NEED_MODIFY') }}</span><button class="text-woot-600 bg-transparent border-none cursor-pointer text-base" @click="handleCancelPayment">{{ $t('PAYMENT_MGMT.WAITING_PAYMENT.CANCEL_ORDER') }}</button><span>{{ $t('PAYMENT_MGMT.PAYMENT_USDT.RESELECT') }}</span>
        </div>
        <div v-else class="my-2 px-3 py-2 max-w-2xl border bg-white border-black-300">
          <h3 class="text-base font-bold">{{ $t('PAYMENT_MGMT.PAYMENT_USDT.INVOICE_INFO') }}</h3>
          <div class="pb-2">
            {{ $t('PAYMENT_MGMT.PAYMENT_USDT.PLAN') }}{{ plan_name }}<br />
            {{ $t('PAYMENT_MGMT.PAYMENT_USDT.USER_COUNT') }}{{ user_count }}<br />
            {{ $t('PAYMENT_MGMT.PAYMENT_USDT.PRICE') }}{{ amount }} U<br />
          </div>
          <span class="text-base">{{ $t('PAYMENT_MGMT.PAYMENT_USDT.NEED_MODIFY') }}</span><button class="text-woot-600 bg-transparent border-none cursor-pointer text-base" @click="handleCancelPayment">{{ $t('PAYMENT_MGMT.WAITING_PAYMENT.CANCEL_ORDER') }}</button><span>{{ $t('PAYMENT_MGMT.PAYMENT_USDT.RESELECT') }}</span>
        </div>
      </div>
    </div>
    <div class="flex-1 overflow-auto p-4">
      <modal :show.sync="isModalVisible" :on-close="closeQrcode">
        <img :src="'data:image/svg+xml;base64,' + qrcode" alt="QR Code" />
      </modal>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import Modal from '../../../../components/Modal';
export default {
  components: {
    Modal,
  },
  mixins: [alertMixin],
  data() {
    return {
      isModalVisible: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.dispatch('payment/makePayment', {})
        .then(() => {})
        .catch((_error) => {
          vm.$router.push({
            name: 'payment_list',
            params: { accountId: to.params.accountId },
          });
        });
    });
  },
  computed: {
    ...mapGetters({
      usdtData: 'payment/getUsdtData',
      uiflags: 'payment/getUIFlags',
      subscriptions: 'payment/getSubscriptions',
    }),
    plan_name() {
      return this.usdtData.plan_name;
    },
    amount() {
      return this.usdtData.amount;
    },
    user_count() {
      return this.usdtData.user_count;
    },
    address() {
      return this.usdtData.address;
    },
    qrcode() {
      return this.usdtData.qrcode;
    },
    invoice_type() {
      return this.usdtData.invoice_type;
    },
    isUpgrade() {
      return this.invoice_type === 'upgrade';
    },
    notice() {
      return this.$t('PAYMENT_MGMT.PAYMENT_USDT.NOTICE');
    },
    description() {
      if (this.isUpgrade) {
        return this.$t('PAYMENT_MGMT.PAYMENT_USDT.UPGRADE_DESCRIPTION');
      }
      return this.$t('PAYMENT_MGMT.PAYMENT_USDT.DESCRIPTION');
    },
    currentPlanId() {
      if (this.subscriptions.length > 0) {
        return this.subscriptions[0].plan_id;
      }
      return 0;
    },
    currentPlanName() {
      switch (this.currentPlanId) {
        case 1: return 'Pro';
        case 2: return 'Team';
        case 3: return 'Enterprise';
        default: return '';
      }
    },
    currentUserCount() {
      if (this.subscriptions.length > 0) {
        return this.subscriptions[0].user_count;
      }
      return 0;
    },
  },
  methods: {
    selectPlan(plan) {
      this.selectedPlan = plan;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.showAlert(this.$t('PAYMENT_MGMT.PAYMENT_USDT.COPY_TO_CLIPBOARD'));
      }).catch(_err => {
      });
    },
    showQrcode() {
      this.isModalVisible = true;
    },
    closeQrcode() {
      this.isModalVisible = false;
    },
    async handleCancelPayment() {
      try {
        await this.$store.dispatch('payment/cancelPayment', this.usdtData.invoice_id);
        this.$router.push({
          name: 'payment_list',
          params: { accountId: this.$route.params.accountId },
        });
      } catch (error) {
        const errorMessage = error?.message || '';
        this.showAlert(errorMessage);
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.usdt--input {
  @apply border-woot-600 cursor-default bg-yellow-300 font-mono;
  width: initial;
}
</style>
